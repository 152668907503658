<template>
  <div class="row">
    <div class="col-12 mb-0" v-for="product in products" :key="product.idPrd">
      <div
        class="alert"
        :class="disableProduct(product)"
        @click="selectProduct(product)"
      >
        <div class="d-flex justify-content-between">
          <span class="fw-bold">{{ product.description }}</span>
          <span>{{
            product.clientPrice
              ? formatToCurrencyBr(product.clientPrice)
              : formatToCurrencyBr(product.defaultPrice)
          }}</span>
        </div>
      </div>
    </div>
    <template v-if="currentProduct.idPrd">
      <div class="col-12 col-md-6 mt">
        <div class="form-floating">
          <input
            type="text"
            class="form-control"
            :class="{ 'is-invalid': v$.quantity.$errors.length }"
            placeholder="XXXX"
            v-model="v$.quantity.$model"
          />
          <label>Quantidade (T):</label>
          <!-- <span>
            Quantidade disponível é de
            {{ maxValueByClient }} T.
          </span> -->
          <span v-if="v$.quantity.required.$invalid" class="invalid-feedback">
            O campo é obrigatório
          </span>
          <span v-if="v$.quantity.minValue.$invalid" class="invalid-feedback">
            Quantidade não é válida.
          </span>
          <span v-if="v$.quantity.maxValue.$invalid" class="invalid-feedback">
            Quantidade máxima disponível é de
            {{ v$.quantity.maxValue.$params.max }} T.
          </span>
        </div>
      </div>

      <div class="col-12 col-md-6">
        <div class="form-floating mb-3">
          <input
            type="text"
            disabled
            class="form-control"
            placeholder="Digite a quantidade desejada"
            v-model="value"
          />
          <label>Valor:</label>
        </div>
      </div>
    </template>
    <hr />
    <div class="col-12 text-end">
      <button
        class="btn btn-secondary btn-lg"
        :disabled="!canFoward || v$.$invalid"
        @click="confirmProduct()"
      >
        CONTINUAR
      </button>
    </div>
  </div>
</template>
<style>
.cursor-not-allowed {
  cursor: not-allowed;
  pointer-events: all !important;
}
</style>
<script>
import { useVuelidate } from '@vuelidate/core'
import { required, minValue, maxValue } from '@vuelidate/validators'
import { formatToCurrencyBr } from '@/utils/strings'

export default {
  name: 'SelectProduct',
  props: {
    products: {
      type: Array,
      required: true,
    },
    orderData: {
      type: Object,
      required: true,
    },
  },
  setup() {
    return { v$: useVuelidate() }
  },

  components: {},
  data() {
    return {
      formatToCurrencyBr,
      quantity: 1,
      value: 0,
      currentProduct: { idPrd: null, amount: 0 },
    }
  },
  computed: {
    // eslint-disable-next-line vue/return-in-computed-property

    canFoward() {
      return this.currentProduct && this.quantity > 0
    },
    maxValueByClient() {
      const avaliable =
        this.currentProduct.amount -
        this.currentProduct.orderDay -
        this.currentProduct.revenueDay
      return avaliable < 30 ? avaliable : 30
    },
  },
  watch: {
    quantity: {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.quantity = parseFloat(newValue)
          if (Number.isNaN(this.quantity)) {
            this.quantity = 0
          }
          if (newValue > 0.5) {
            this.calculateValue()
          } else {
            this.value = 0
          }
        }
      },
      immediate: true,
    },
    currentProduct: {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          if (Number.isNaN(this.quantity)) {
            this.quantity = 0
          }
          this.calculateValue()
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.currentProduct = this.orderData.product
    this.quantity = this.orderData.quantity
  },
  methods: {
    isAvaliable(product) {
      if (
        parseFloat(product.amount) -
          parseFloat(product.orderDay) -
          parseFloat(product.revenueDay) <=
        0
      ) {
        return false
      }
      return true
    },
    disableProduct(product) {
      if (!this.isAvaliable(product)) {
        return 'alert-dark border-dark cursor-not-allowed disabled no'
      }
      if (product === this.currentProduct) {
        return 'alert-primary border cursor-pointer border-primary'
      }
      return 'alert-primary border cursor-pointer'
    },
    selectProduct(product) {
      this.currentProduct = product
    },
    calculateValue() {
      if (this.currentProduct) {
        const price = this.currentProduct.clientPrice
          ? this.currentProduct.clientPrice
          : this.currentProduct.defaultPrice
        this.value = formatToCurrencyBr(price * this.quantity)
      }
    },
    confirmProduct() {
      this.$emit('setProduct', {
        quantity: this.quantity,
        product: this.currentProduct,
      })
    },
  },
  validations() {
    return {
      quantity: {
        required,
        minValue: minValue(0),
        maxValue: maxValue(this.maxValueByClient),
      },
    }
  },
}
</script>
