<template>
  <form @submit.prevent="save">
    <div class="row">
      <div class="col-12 mb-3">
        <div class="form-floating">
          <input
            type="text"
            class="form-control"
            :class="{ 'is-invalid': v$.name.$errors.length }"
            placeholder="Nome do Moorista"
            v-model="v$.name.$model"
          />
          <label>Nome do Motorista:</label>
          <span v-if="v$.name.required.$invalid" class="invalid-feedback">
            O campo é obrigatório
          </span>
          <span v-if="v$.name.minLength.$invalid" class="invalid-feedback">
            É necessário pelo menos
            {{ v$.name.minLength.$params.min }} caracteres
          </span>
          <span v-if="v$.name.maxLength.$invalid" class="invalid-feedback">
            Deve ter no máximo
            {{ v$.name.maxLength.$params.max }} caracteres
          </span>
        </div>
      </div>
      <div class="col-12 col-md-6 mb-3">
        <div class="form-floating">
          <input
            type="text"
            class="form-control"
            :class="{ 'is-invalid': v$.licensePlate.$errors.length }"
            placeholder="Nome do Motorista"
            v-model="v$.licensePlate.$model"
            v-maska="'AAA-#X##'"
          />
          <label>Placa do Veículo:</label>
          <span
            v-if="v$.licensePlate.required.$invalid"
            class="invalid-feedback"
          >
            O campo é obrigatório
          </span>
          <span
            v-if="v$.licensePlate.minLength.$invalid"
            class="invalid-feedback"
          >
            É necessário pelo menos
            {{ v$.licensePlate.minLength.$params.max }} caracteres
          </span>
          <span
            v-if="v$.licensePlate.maxLength.$invalid"
            class="invalid-feedback"
          >
            Deve ter no máximo
            {{ v$.licensePlate.maxLength.$params.max }} caracteres
          </span>
        </div>
      </div>
      <div class="col-12 col-md-6 mb-3">
        <div class="form-floating">
          <input
            type="text"
            class="form-control"
            :class="{ 'is-invalid': v$.phone.$errors.length }"
            placeholder="Número de WhatsApp"
            v-maska="['(##) ####-####', '(##) #####-####']"
            v-model="v$.phone.$model"
          />
          <label>WhatsApp:</label>
          <span v-if="v$.phone.required.$invalid" class="invalid-feedback">
            O campo é obrigatório
          </span>
          <span v-if="v$.phone.minLength.$invalid" class="invalid-feedback">
            É necessário pelo menos
            {{ v$.phone.minLength.$params.min }} caracteres
          </span>
          <span v-if="v$.phone.maxLength.$invalid" class="invalid-feedback">
            Deve ter no máximo
            {{ v$.phone.maxLength.$params.max }} caracteres
          </span>
        </div>
      </div>

      <div class="col-12 text-end">
        <button
          type="button"
          class="btn btn-primary btn-lg me-2"
          @click="backToPreviousStep()"
        >
          ALTERAR PRODUTO
        </button>
        <button class="btn btn-secondary btn-lg" :disabled="v$.$invalid">
          CONTINUAR
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import { maska } from 'maska'
import { useVuelidate } from '@vuelidate/core'
import { required, minLength, maxLength } from '@vuelidate/validators'

export default {
  name: 'SelectDriver',
  props: {
    orderData: {
      type: Object,
      required: true,
    },
  },
  setup() {
    return { v$: useVuelidate() }
  },
  directives: { maska },
  components: {},
  data() {
    return {
      name: 1,
      licensePlate: 0,
      phone: null,
    }
  },
  computed: {},
  watch: {},
  mounted() {
    this.name = this.orderData.driver.name ?? ''
    this.licensePlate = this.orderData.driver.licensePlate ?? ''
    this.phone = this.orderData.driver.phone ?? ''
  },
  methods: {
    backToPreviousStep() {
      this.$emit('backToPreviousStep')
    },
    async save() {
      if (!this.v$.$invalid) {
        try {
          this.$emit('setDriver', {
            name: this.name,
            licensePlate: this.licensePlate,
            phone: this.phone,
          })
        } catch (error) {
          if (error && error.message) {
            this.$root.$refs.notification.warning(error.message)
          } else {
            this.$root.$refs.notification.error(
              'Não foi possível se conectar ao servidor.',
            )
          }
        }
      }
    },
  },
  validations() {
    return {
      name: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(30),
      },
      licensePlate: {
        required,
        minLength: minLength(8),
        maxLength: maxLength(9),
      },
      phone: {
        required,
        minLength: minLength(14),
        maxLength: maxLength(15),
      },
    }
  },
}
</script>
