<template>
  <div class="h-100">
    <div class="row">
      <div class="col-12 p-4">
        <div class="d-flex">
          <h1 class="text-start mb-0 text-primary">
            <router-link
              to="/home"
              type="button"
              class="btn btn-primary btn-lg btn-icon rounded-pill"
            >
              <i class="ph-caret-left ph-lg"></i>
            </router-link>
            <span class="fw-bold"> NOVO PEDIDO</span>
          </h1>
        </div>
      </div>
      <div class="col-12 p-4" :class="currentStep > 1 ? 'col-md-8' : ''">
        <div class="timeline timeline-start">
          <div class="timeline-container">
            <div class="timeline-row">
              <div class="timeline-icon">
                <div
                  class="text-white"
                  :class="currentStep >= 1 ? 'bg-secondary' : 'bg-primary'"
                >
                  1
                </div>
              </div>
              <div class="card">
                <div class="card-header d-flex align-items-center">
                  <h4 class="mb-0 text-secondary">
                    ESCOLHA O PRODUTO DESEJADO
                  </h4>
                </div>
                <div class="card-body" v-if="isLoadingProducts">
                  <LoadingData class="my-3" />
                </div>
                <div class="card-body" v-else-if="currentStep == 1">
                  <SelectProduct
                    :products="products"
                    :orderData="orderData"
                    @setProduct="setProduct"
                    @backToPreviousStep="backToPreviousStep"
                  />
                </div>
              </div>
            </div>
            <div class="timeline-row">
              <div class="timeline-icon">
                <div
                  class="text-white"
                  :class="currentStep >= 2 ? 'bg-secondary' : 'bg-primary'"
                >
                  2
                </div>
              </div>
              <div class="card">
                <div class="card-header d-flex align-items-center">
                  <h4 class="mb-0 text-secondary">DADOS DO TRANSPORTE</h4>
                </div>

                <div class="card-body" v-show="currentStep == 2">
                  <SelectDriver
                    :orderData="orderData"
                    @setDriver="setDriver"
                    @backToPreviousStep="backToPreviousStep"
                  />
                </div>
              </div>
            </div>
            <div class="timeline-row">
              <div class="timeline-icon">
                <div
                  class="text-white"
                  :class="currentStep >= 3 ? 'bg-secondary' : 'bg-primary'"
                >
                  3
                </div>
              </div>
              <div class="card">
                <div class="card-header d-flex align-items-center">
                  <h4 class="mb-0 text-secondary">INFORMAÇÕES DO PAGAMENTO</h4>
                </div>
                <div class="card-body" v-show="currentStep == 3">
                  <SelectPaymentCondition
                    :orderData="orderData"
                    :disableButton="isSavingOrder"
                    @setPaymentCondition="setPaymentCondition"
                    @backToPreviousStep="backToPreviousStep"
                  />
                  <div
                    class="alert alert-warning border-0 alert-dismissible fade show mt-3"
                    v-if="isError"
                  >
                    <span class="fw-semibold">Erro!</span> {{ textError }}
                    <button
                      type="button"
                      class="btn-close"
                      data-bs-dismiss="alert"
                    ></button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-4 p-4" v-if="currentStep > 1">
        <div class="card">
          <div class="card-header">
            <h4 class="fw-bold text-center">RESUMO DO PEDIDO</h4>
          </div>
          <div class="card-body">
            <h6>PRODUTO:</h6>
            <p>
              <i class="ph-cube me-2"></i><strong>NOME:</strong>
              {{ orderData.product.description }}
            </p>
            <p>
              <i class="ph-scales me-2"></i><strong>QUANTIDADE:</strong>
              {{ orderData.quantity }} TONELADAS
            </p>
            <p>
              <i class="ph-tag me-2"></i><strong>VALOR DA TONELADA:</strong>
              {{
                orderData.product.clientPrice
                  ? formatToCurrencyBr(orderData.product.clientPrice)
                  : formatToCurrencyBr(orderData.product.defaultPrice)
              }}
            </p>
            <p>
              <i class="ph-currency-dollar me-2"></i
              ><strong>VALOR TOTAL:</strong>
              {{
                orderData.product.clientPrice
                  ? formatToCurrencyBr(
                      orderData.product.clientPrice * orderData.quantity,
                    )
                  : formatToCurrencyBr(
                      orderData.product.defaultPrice * orderData.quantity,
                    )
              }}
            </p>
            <div v-if="orderData.driver.name">
              <hr />
              <h6>TRANSPORTE:</h6>
              <p>
                <i class="ph-user me-2"></i><strong>MOTORISTA:</strong>
                {{ orderData.driver.name }}
              </p>
              <p>
                <i class="ph-truck me-2"></i><strong>PLACA:</strong>
                {{ orderData.driver.licensePlate }}
              </p>
              <p>
                <i class="ph-whatsapp-logo me-2"></i><strong>WHATSAPP:</strong>
                {{ orderData.driver.phone }}
              </p>
            </div>
            <p></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PATHS from '@/router/routesMap'
import LoadingData from '@/components/commons/LoadingData.vue'
import SelectProduct from '@/components/newOrder/SelectProduct.vue'
import SelectDriver from '@/components/newOrder/SelectDriver.vue'
import SelectPaymentCondition from '@/components/newOrder/SelectPaymentCondition.vue'
import { dateTimeSqlToDateTimeBR } from '@/utils/date'
import { formatToCurrencyBr } from '@/utils/strings'

export default {
  name: 'Home',
  components: {
    LoadingData,
    SelectProduct,
    SelectDriver,
    SelectPaymentCondition,
  },
  data() {
    return {
      dateTimeSqlToDateTimeBR,
      formatToCurrencyBr,
      currentStep: 1,
      products: [],
      paymentConditions: [],
      isLoadingProducts: true,
      isLoadingPaymentConditions: true,
      isSavingOrder: false,
      isError: false,
      textError: '',
      orderData: {
        product: {
          amount: 0,
        },
        quantity: 0,
        driver: {
          name: '',
          licensePlate: '',
          phone: '',
        },
        paymentCondition: {
          codCpg: '0004',
          descriptionCpg: '30 DIAS',
          formPayment: 'BOLETO',
        },
      },
    }
  },
  mounted() {
    this.getProducts()
    // this.getPaymentConditions()
  },
  methods: {
    async getProducts() {
      try {
        this.isLoadingProducts = true
        this.products = await this.$store.dispatch('getProducts')
        this.isLoadingProducts = false
      } catch (error) {
        this.disableButton = false
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },
    async getPaymentConditions() {
      try {
        this.isLoadingPaymentConditions = true
        this.paymentConditions = await this.$store.dispatch(
          'getPaymentConditions',
        )
        this.isLoadingPaymentConditions = false
      } catch (error) {
        this.disableButton = false
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },
    backToPreviousStep() {
      // eslint-disable-next-line no-plusplus
      this.currentStep--
    },
    setProduct(product) {
      this.orderData.product = product.product
      this.orderData.quantity = product.quantity
      this.currentStep = 2
    },
    setDriver(driver) {
      this.orderData.driver = driver
      this.currentStep = 3
    },
    setPaymentCondition(paymentCondition) {
      this.orderData.paymentCondition = paymentCondition
      this.saveOrder()
    },
    async saveOrder() {
      try {
        this.isError = false
        this.textError = ''
        this.isSavingOrder = true
        const order = await this.$store.dispatch('saveOrder', this.orderData)
        this.isSavingOrder = false
        this.$router.push(PATHS.HOME)
      } catch (error) {
        this.isSavingOrder = false
        if (error && error.message) {
          this.isError = true
          this.textError = error.message
          // this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },
  },
}
</script>
