<template>
  <form @submit.prevent="save">
    <div class="row">
      <div class="col-12">
        <h5>DADOS DE PAGAMENTO:</h5>
      </div>
      <div class="col-12">
        <p><strong>CONDIÇÃO DE PAGAMENTO: </strong> {{ descriptionCpg }}</p>
        <p><strong>FORMA DE PAGAMENTO: </strong> {{ formPayment }}</p>
      </div>

      <div class="col-12 text-end">
        <button
          type="button"
          class="btn btn-primary btn-lg me-2"
          @click="backToPreviousStep()"
        >
          ALTERAR TRANSPORTE
        </button>
        <button class="btn btn-secondary btn-lg" :disabled="disableButton">
          {{ disableButton ? 'REALIZANDO PEDIDO, AGUARDE' : 'CONCLUIR PEDIDO' }}
        </button>
      </div>
    </div>
  </form>
</template>

<script>
export default {
  name: 'SelectPaymentCondition',
  props: {
    orderData: {
      type: Object,
      required: true,
    },
    disableButton: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      codCpg: 1,
      descriptionCpg: '30 DIAS',
      formPayment: 'BOLETO',
    }
  },
  mounted() {
    this.codCpg = this.orderData.paymentCondition.codCpg ?? ''
    this.descriptionCpg = this.orderData.paymentCondition.descriptionCpg ?? ''
    this.formPayment = this.orderData.paymentCondition.formPayment ?? ''
  },
  methods: {
    backToPreviousStep() {
      this.$emit('backToPreviousStep')
    },
    async save() {
      try {
        this.$emit('setPaymentCondition', {
          codCpg: this.codCpg,
          descriptionCpg: this.descriptionCpg,
          formPayment: this.formPayment,
        })
      } catch (error) {
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },
  },
}
</script>
