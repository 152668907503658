import { dateTimeSqlToDateTimeBR } from './date'

const createdUpdatedUser = (userName, date) => {
  return userName !== null && userName !== ''
    ? `${userName} em ${dateTimeSqlToDateTimeBR(date)}`
    : '-'
}

const statusTranslate = (value) => {
  const status = parseInt(value, 10)
  switch (status) {
    case 0:
      return '<span class="badge bg-danger">Inativo</span>'
    case 1:
      return '<span class="badge bg-success">Ativo</span>'
    default:
      return '<span class="badge bg-secondary">Indefinido</span>'
  }
}

const statusIconTranslate = (value) => {
  switch (value) {
    case 'W':
      return '<button type="button" class="btn btn-warning rounded-pill"><i class="ph-clock"></i></button>'
    case 'S':
      return '<button type="button" class="btn btn-warning rounded-pill"><i class="ph-warning"></i></button>'
    case 'L':
      return '<button type="button" class="btn btn-info rounded-pill"><i class="ph-warning"></i></button>'
    case 'C':
      return '<button type="button" class="btn btn-danger rounded-pill"><i class="ph-x"></i></button>'
    case 'O':
      return '<button type="button" class="btn btn-success rounded-pill"><i class="ph-check"></i></button>'
    default:
      return '<button type="button" class="btn btn-dark rounded-pill"><i class="ph-check"></i></button>'
  }
}

const statusLabelTranslate = (value, pos) => {
  switch (value) {
    case 'W':
      return `${pos}º NA FILA`
    case 'S':
      return 'CONVOCADO'
    case 'L':
      return 'CARREGANDO'
    case 'C':
      return 'CANCELADO'
    case 'O':
      return 'CONCLUÍDO'
    default:
      return 'INDEFINIDO'
  }
}

const formatToCurrencyBr = (value) => {
  const money = parseFloat(value)
  return money.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
}

export {
  createdUpdatedUser,
  statusTranslate,
  statusIconTranslate,
  statusLabelTranslate,
  formatToCurrencyBr,
}
