<template>
  <div class="card border shadow-none">
    <div class="card-header border-bottom-0">
      <h6 class="mb-0">
        <a
          data-bs-toggle="collapse"
          class="text-body"
          :href="`#order-queue-${order.id}`"
        >
          <div class="row align-items-center">
            <div class="col-2 col-md-1">
              <div v-html="statusIconTranslate(order.status)"></div>
            </div>
            <div class="col-10 col-md-4 align-items-center">
              <p class="text-start mb-0">
                <strong>{{
                  statusLabelTranslate(order.status, order.currentPosition)
                }}</strong>
              </p>
            </div>
            <div class="col-md-4">
              <strong>NÚMERO DO PEDIDO:</strong> {{ order.number }}
            </div>
            <div class="col-md-3">
              <strong>DATA:</strong>
              {{ dateTimeSqlToDateTimeBR(order.createdAt) }}
            </div>
          </div>
        </a>
      </h6>
    </div>

    <div :id="`order-queue-${order.id}`" class="collapse border-top">
      <div class="card-body">
        <div class="row w-100">
          <p class="col-12 col-md-6">
            <strong>NÚMERO DO PEDIDO:</strong> {{ order.number }}
          </p>
          <p class="col-12 col-md-6">
            <strong>DATA:</strong>
            {{ dateTimeSqlToDateTimeBR(order.createdAt) }}
          </p>
          <p class="col-12 col-md-6">
            <strong>PRODUTO:</strong> {{ order.product }}
          </p>
          <p class="col-12 col-md-6">
            <strong>QUANTIDADE:</strong>
            {{ parseFloat(order.quantity) }} TONELADAS
          </p>
          <p class="col-12 col-md-6">
            <strong>PREÇO:</strong>
            {{ formatToCurrencyBr(parseFloat(order.price)) }}
          </p>
          <p class="col-12 col-md-6">
            <strong>VALOR TOTAL:</strong>
            {{
              formatToCurrencyBr(
                parseFloat(order.price) * parseFloat(order.quantity),
              )
            }}
          </p>
          <p class="col-12 col-md-6">
            <strong>PLACA:</strong> {{ order.licencePlate }}
          </p>
          <p class="col-12 col-md-6">
            <strong>MOTORISTA:</strong> {{ order.driver }}
          </p>
          <p class="col-12 col-md-6">
            <strong>TELEFONE DO MOTORISTA:</strong> {{ order.driverContact }}
          </p>

          <p class="col-12 col-md-6">
            <strong>ÚLTIMA ATUALIZAÇÃO:</strong>
            {{ dateTimeSqlToDateTimeBR(order.lastUpdated) }}
          </p>
          <div class="mb-0 text-end" v-if="order.statusMov === 'A'">
            <button
              class="btn btn-danger"
              @click="cancelOrder(order.idMov)"
              :disabled="isCancelingOrder"
            >
              {{
                isCancelingOrder
                  ? 'CANCELANDO PEDIDO, AGUARDE...'
                  : 'CANCELAR PEDIDO'
              }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { dateTimeSqlToDateTimeBR } from '@/utils/date'
import {
  statusIconTranslate,
  statusLabelTranslate,
  formatToCurrencyBr,
} from '@/utils/strings'

export default {
  name: 'OrderCard',
  props: {
    order: { type: Object, required: true },
  },
  data() {
    return {
      dateTimeSqlToDateTimeBR,
      statusIconTranslate,
      formatToCurrencyBr,
      statusLabelTranslate,
      isCancelingOrder: false,
    }
  },
  methods: {
    async cancelOrder(idMov) {
      try {
        this.isCancelingOrder = true
        const response = await this.$store.dispatch('cancelOrder', idMov)
        this.$root.$refs.notification.success(response.message)
        this.$emit('getOrders')
      } catch (error) {
        this.isCancelingOrder = false
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },
  },
}
</script>
