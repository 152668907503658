<template>
  <div class="h-100">
    <div id="home-content" class="row">
      <div class="col-12 mb-4">
        <div class="d-flex justify-content-between">
          <h2 class="text-start mb-0">BEM-VINDO!</h2>

          <router-link
            to="/orders/new"
            type="button"
            class="btn btn-primary btn-lg fw-bold"
          >
            <i class="ph-plus ph-lg me-2"></i>
            <span class="d-none d-md-flex"> CADASTRAR PEDIDO</span>
            <span class="d-flex d-md-none"> PEDIDO</span>
          </router-link>
        </div>
      </div>
      <div class="col-12">
        <div
          class="my-5 d-flex justify-content-center align-items-center"
          v-if="isLoadingOrders"
        >
          <div class="text-center">
            <i class="ph-spinner ph-3x spinner"></i>
            <h4>Aguarde!</h4>
            <p>Carregando pedidos...</p>
          </div>
        </div>
        <div class="row" v-else>
          <h4>PEDIDOS EM ANDAMENTO</h4>
          <div class="col-12 card-item" v-for="order in orders" :key="order.id">
            <OrderCard :order="order" @getOrders="getOrders" />
          </div>
          <div class="col-12" v-if="orders.length === 0">
            <h4 class="text-center">
              Não há pedidos em andamento, clique em "Cadastrar pedido" para
              realizar um novo pedido.
            </h4>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
h2 {
  font-size: 2rem;
  font-weight: 900;
}
#home-content {
  padding: 1.875rem !important;
}
.status-container {
  min-width: 160px;
}
@media screen and (max-width: 767px) {
  h2 {
    font-size: 1.5rem;
    font-weight: 900;
  }
  #home-content {
    padding: 0.25rem !important;
  }
  .status-container {
    min-width: 120px;
  }
  .card-item > .card-body {
    padding: 0.5rem !important;
  }
}
</style>
<script>
import { dateTimeSqlToDateTimeBR } from '@/utils/date'
import { statusIconTranslate, statusLabelTranslate } from '@/utils/strings'
import OrderCard from '@/components/home/OrderCard.vue'

export default {
  name: 'Home',
  components: {
    OrderCard,
  },
  data() {
    return {
      dateTimeSqlToDateTimeBR,
      statusIconTranslate,
      statusLabelTranslate,
      orders: [],
      isLoadingOrders: true,

      interval: null,
    }
  },
  mounted() {
    this.getOrders()
    this.interval = setInterval(() => {
      this.getOrders()
    }, 120000) // 2 minutes
  },
  beforeUnmount() {
    clearInterval(this.interval) // clear the interval when the component is destroyed
  },
  methods: {
    async getOrders() {
      try {
        // this.isLoadingOrders = true
        this.orders = await this.$store.dispatch('getLastOrders')
        this.isLoadingOrders = false
      } catch (error) {
        this.disableButton = false
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },
    async cancelOrder(idMov) {
      try {
        // this.isLoadingOrders = true
        const response = await this.$store.dispatch('cancelOrder', idMov)
        this.$root.$refs.notification.success(response.message)
        this.getOrders()
      } catch (error) {
        this.disableButton = false
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },
  },
}
</script>
